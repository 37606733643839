<template>
    <div class="firmware-list">
        <app-header title="固件管理" :isShowBack="true"></app-header>
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
</template>
<script>
    import Vue from "vue";
    import axios from "axios";
    import cabinetUtil from "@utils/cabinetUtil.js"
    export default {
        data() {
            let _this = this;
            return {
                dialogVisible: false,
                opt: {
                    // title: "固件管理",
                    search: [{
                        key: "counterType",
                        label: "货柜型号",
                        type: "select",
                        opt: {
                            list: []
                        }
                    }],
                    columns: [{
                            label: "货柜型号",
                            key: "counterTypeStr",
                        },
                        {
                            label: "固件版本",
                            key: "version"
                        },
                        {
                            label: "从柜固件版本",
                            key: "subHwVersion"
                        },
                        {
                            label: "固件文件名",
                            key: "fileName"
                        },
                        {
                            label: "固件大小",
                            key: "fileSize"
                        },
                        {
                            label: "版本说明",
                            key: "remark",
                            opt: {
                                isUserPopover: true
                            }
                        },
                        {
                            label: "上传时间",
                            key: "updatedTime"
                        },
                        {
                            label: "上传账号",
                            key: "upgradeUser"
                        },
                        {
                            label: "操作",
                            key: "action",
                            type: "action-but",
                            opt: {
                                list: [{
                                    label: "下载",
                                    on(row) {
                                        var a = document.createElement("a");
                                        // 预发布和生产 需要将地址转成https协议 否则无法下载
                                        if(Vue.config.BASE_URL == 'https://apartment-preapi.wfyundata.com' || Vue.config.BASE_URL == 'https://apartment-api.wavewisdom.com') {
                                            a.href = row.fileUrl.replace(/http/g, "https");
                                        }else {
                                            a.href = row.fileUrl;
                                        }
                                        a.download = row.fileName;
                                        $("body").append(a);
                                        a.click();
                                        $(a).remove();
                                    }
                                }]
                            }
                        }
                    ],
                    buttons: [{
                            type: 0,
                            name: "上传固件",
                            on() {
                                _this.$router.push({
                                    path: "/main/firmware/detail/add",
                                    query: {
                                        type: 'add'
                                    }
                                })

                            }
                        },
                        {
                            type: 1,
                            on(data) {
                                _this.$router.push({
                                    path: "/main/firmware/detail/edit",
                                    query: {
                                        id: data.id,
                                        type: 'edit'
                                    }
                                })
                            }
                        },
                        {
                            type: 2,
                            on(data) {
                                if (data.ids.length > 1) {
                                    return _this.$message({
                                        showClose: true,
                                        message: "不支持多个删除,只支持单个删除!",
                                        type: "warning"
                                    });
                                }
                                const h = _this.$createElement;
                                _this.$msgbox({
                                        title: "删除提示",
                                        message: h("p", null, [
                                            h(
                                                "span", {
                                                    style: "color: #333;font-size: 16px;margin-left:98px"
                                                },
                                                "确定要删除吗？"
                                            )
                                        ]),
                                        showCancelButton: true,
                                        confirmButtonText: "确定",
                                        cancelButtonText: "取消"
                                    }).then(action => {
                                        _this.delete("mall-service/v1/mall/firmware/delete/" + data.ids[0]).then(function () {
                                                _this.$message({
                                                    showClose: true,
                                                    message: "删除成功！",
                                                    type: "success"
                                                });
                                                data.refresh();
                                            });
                                    });
                            }
                        }
                    ]
                },
            };
        },
        created() {
            console.log("firmware-list created!!");
        },
        async activated() {
            try {
                let list = await cabinetUtil.getContainerModel();
                this.opt.search.forEach(item => {
                    if (item.key == 'counterType') {
                        item.opt.list = list;
                    }
                })
            } catch (error) {
                console.log(error)
            }
        },

        methods: {
            onGet(opt) {
                let dto = {
                    pageCurrent: opt.skip,
                    pageSize: opt.limit,
                };
                // WF-L21BD WF-L21BX WF-L22BD WF-L22BX
                if (opt.searchForm.counterType == 4) {
                    dto.counterType = 6;
                    dto.size = 1;
                } else if (opt.searchForm.counterType == 5) {
                    dto.counterType = 6;
                    dto.size = 2;
                } else if (opt.searchForm.counterType == 6) {
                    dto.counterType = 7;
                    dto.size = 1;
                } else if (opt.searchForm.counterType == 7) {
                    dto.counterType = 7;
                    dto.size = 2;
                } else {
                    // 其它货柜类型
                    dto.counterType = opt.searchForm.counterType;
                }
                this.post("mall-service/v1/mall/firmware/page/query", dto, {
                    isUseResponse: true
                }).then(res => {
                    let obj = {
                        data: {
                            data: [],
                            total: 0,
                        }
                    }
                    obj.data.total = res.data.data.total;
                    obj.data.data = res.data.data.data;
                    obj.data.data.forEach(item => {
                        item.counterTypeStr = cabinetUtil.getContainerTypeName(item);
                    })
                    opt.cb(obj.data);
                });
            },
        }
    };
</script>
<style lang="scss">

</style>